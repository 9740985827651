import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@material-ui/core';

import LocalStorageService from '../services/localstorage.service';
import { verifyToken } from '../utils/helpers';

import { useStyles } from './styles';

const GuardedRoute = ({ component: Component, path, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const token = LocalStorageService.get('token');
    if (token) {
      setToken(token);
    } else {
      console.log('Go to the sign in page');
      history.push('/signin');
    }
  }, [history]);

  useEffect(() => {
    if (token) {
      const isAuthenticated = verifyToken(token);
      if (isAuthenticated) {
        setIsAuthenticated(isAuthenticated);
        setIsLoading(false);
      } else {
        LocalStorageService.remove('token');
        history.push('/signin');
      }
    }
  }, [token, history]);

  return isLoading ? (
    <div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  ) : (
    isAuthenticated && (
      <Route
        path={path}
        {...rest}
        render={(props) => <Component {...props} />}
      />
    )
  );
};

export default GuardedRoute;
