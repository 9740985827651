import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  Paper,
  Link,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { ShoppingCart, EventNote, Schedule, History } from '@material-ui/icons';

import Navbar from '../components/shared/navbar.component';

import { useStyles } from '../utils/main-styles';

const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid container spacing={3} alignItems="center" alignContent="center">
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <Link
                  color="textPrimary"
                  underline="none"
                  className={classes.link}
                  onClick={() => {
                    history.push('/place-order');
                  }}
                >
                  <CardContent>
                    <ShoppingCart />
                    &nbsp;&nbsp;
                    <Typography variant="h6">Place Order</Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <Link
                  color="textPrimary"
                  underline="none"
                  className={classes.link}
                  onClick={() => {
                    history.push('/estimate-request');
                  }}
                >
                  <CardContent>
                    <EventNote />
                    &nbsp;&nbsp;
                    <Typography variant="h6">Estimate Request</Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <Link
                  color="textPrimary"
                  underline="none"
                  className={classes.link}
                  onClick={() => {
                    history.push('/scheduled-jobs');
                  }}
                >
                  <CardContent>
                    <Schedule />
                    &nbsp;&nbsp;
                    <Typography variant="h6">Scheduled Jobs</Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <Link
                  color="textPrimary"
                  underline="none"
                  className={classes.link}
                  onClick={() => {
                    history.push('/order-history');
                  }}
                >
                  <CardContent>
                    <History />
                    &nbsp;&nbsp;
                    <Typography variant="h6">Order History</Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default Home;
