import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1200 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1200 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  appBar: { marginTop: 10, marginBottom: 15 },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  sectionHeader: {
    backgroundColor: '#D3D3D3',
    border: '1px solid #D3D3D3 ',
    padding: 5,
    margin: 0,
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  textBox: {
    padding: 5,
  },
  textItem: {
    marginRight: 5,
  },
  textBold: {
    fontWeight: 600,
  },
  avatarContainer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    marginTop: 20,
    overflowX: 'auto',
  },
  square: {
    width: 150,
    height: 150,
  },
  card: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  link: {
    cursor: 'pointer',
  },
}));
