import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

const AddAnotherUnitDialog = (props) => {
  const {
    openAddUnitDialog,
    handleCloseAddAnotherUnitDialog,
    handleAddAnotherUnit,
  } = props;
  const [unitInSameBuilding, setUnitInSameBuilding] = useState('yes');
  const [needSameExactWork, setNeedSameExactWork] = useState('no');

  return (
    <div>
      <Dialog
        open={openAddUnitDialog}
        onClose={handleCloseAddAnotherUnitDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent dividers>
          <DialogTitle id="form-dialog-title">
            Awesome. You need to schedule another unit.
          </DialogTitle>
          <Box mb={4} mt={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Is this unit in the same building?
              </FormLabel>
              <RadioGroup
                aria-label="unitInSameBuilding"
                name="unitInSameBuilding"
                row
                value={unitInSameBuilding}
                onChange={(e) => {
                  setUnitInSameBuilding(e.target.value);
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Do you need the same exact work done in this unit?
              </FormLabel>
              <RadioGroup
                aria-label="needSameWork"
                name="needSameWork"
                row
                value={needSameExactWork}
                onChange={(e) => {
                  setNeedSameExactWork(e.target.value);
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddAnotherUnitDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleAddAnotherUnit(unitInSameBuilding, needSameExactWork);
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAnotherUnitDialog;
