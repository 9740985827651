import axios from 'axios';
import LocalStorageService from './localstorage.service';
const baseUrl = process.env.REACT_APP_SERVER_URL;

class SBWorkOrdersService {
  constructor() {
    this.url = `${baseUrl}/api/service-bridge-wo`;
  }

  getAll() {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.get(`${this.url}`, { headers });
  }

  getAllPastOrders() {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.get(`${this.url}/history`, { headers });
  }

  getAllFutureOrders() {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.get(`${this.url}/future`, { headers });
  }

  getWOById(id) {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.get(`${this.url}/${id}`, { headers });
  }
}

export default new SBWorkOrdersService();
