import Snackbar from '@material-ui/core/Snackbar';

import Alert from './alert.component';

const SnackbarComponent = (props) => {
  const { openSnackbar, snackbarMessage, handleCloseSnackbar } = props;
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity="success">
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
