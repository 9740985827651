import axios from 'axios';

import LocalStorageService from './localstorage.service';

const baseUrl = process.env.REACT_APP_SERVER_URL;

class ResetPasswordService {
  constructor() {
    this.url = `${baseUrl}/api/reset-password`;
  }

  update(email, password) {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.post(
      `${this.url}/`,
      {
        email,
        password,
      },
      { headers }
    );
  }
}

export default new ResetPasswordService();
