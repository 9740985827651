import axios from 'axios';
import LocalStorageService from './localstorage.service';
const baseUrl = process.env.REACT_APP_SERVER_URL;

class EstimateRequestService {
  constructor() {
    this.url = `${baseUrl}/api/estimate-request`;
  }

  submit(data) {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.post(`${this.url}`, data, {
      headers,
    });
  }

  getAll() {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.get(`${this.url}`, { headers });
  }
}

export default new EstimateRequestService();
