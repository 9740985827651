import React, { useState, useEffect } from 'react';
import { CssBaseline, Paper, Grid, CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

import Navbar from '../components/shared/navbar.component';
import columns from '../components/table/order-details-columns';

import WODetails from '../components/table/wo-details-modal';

import SBWorkOrdersService from '../services/service-bridge-wo.service';

import { useStyles } from '../utils/main-styles';

const OrderHistory = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [woData, setWOData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let response = await SBWorkOrdersService.getAllPastOrders();
      setData(response.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const options = {
    print: false,
    download: false,
    filter: true,
    selectableRows: 'none',
    sortOrder: { name: 'WorkOrderDate', direction: 'desc' },
    onRowClick: async (rowData, rowMeta) => {
      const id = rowData[0];
      try {
        setIsLoading(true);
        let response = await SBWorkOrdersService.getWOById(id);
        setWOData(response.data.data);
        setIsLoading(false);
        setOpenModal(true);
      } catch (e) {
        console.log('Err', e);
      }
    },
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Grid container spacing={3}>
              {isLoading && (
                <Grid item sm={12} xs={12} className={classes.textCenter}>
                  <CircularProgress />
                </Grid>
              )}
              <Grid item sm={12} xs={12}>
                <MUIDataTable
                  title={'Order History'}
                  data={data}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        </Paper>
      </main>
      <WODetails
        woData={woData}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </React.Fragment>
  );
};

export default OrderHistory;
