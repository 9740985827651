import React from 'react';
import {
  makeStyles,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
    },
    textBox: {
      padding: 5,
    },
    textItem: {
      marginRight: 5,
    },
  })
);

const WODetails = (props) => {
  const classes = useStyles();
  const { woData, openModal, handleCloseModal } = props;
  return (
    <Dialog
      onClose={handleCloseModal}
      aria-labelledby="simple-dialog-title"
      open={openModal}
      maxWidth="lg"
      className={(classes.textCenter, classes.dialog)}
    >
      <DialogTitle id="simple-dialog-title">Work Order Details</DialogTitle>
      <DialogContent>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            ID:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Id}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Work Order Number:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.WorkOrderNumber}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Customer:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Customer.Id} - {woData?.Customer.Name}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Location:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Location.Id} - {woData?.Location.Name}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Contact:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Contact.Name}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Sales Representative:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.SalesRepresentative.Id} -{' '}
            {woData?.SalesRepresentative.Name}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Description:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Description}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Status:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Status}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Branch:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Branch.Name}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            WO Date:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.WorkOrderDate}
          </Typography>
        </Box>
        <Box className={classes.textBox}>
          <Typography display="inline" className={classes.textItem}>
            Notes:
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {woData?.Notes}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WODetails;
