import { Typography, Tooltip } from '@material-ui/core';
import { formatDateUS } from '../../utils/helpers';

const columns = [
  {
    name: 'WorkOrderDate',
    label: 'Date',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return formatDateUS(value);
      },
    },
  },
  {
    name: 'WorkOrderNumber',
    label: 'WO #',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'UnitNumber',
    label: 'Unit #',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Description',
    label: 'Description',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        if (value && value.length) {
          return (
            <Tooltip title={value} placement="top-start">
              <Typography variant="body2">{value}</Typography>
            </Tooltip>
          );
        } else {
          return '';
        }
      },
    },
  },
  {
    name: 'WorkOrderLines',
    label: 'WO Total ($)',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (WorkOrderLines) => {
        if (WorkOrderLines && WorkOrderLines.length) {
          return parseFloat(
            WorkOrderLines.reduce((total, item) => total + item.Price, 0)
          ).toFixed(2);
        } else {
          return 0;
        }
      },
    },
  },
  {
    name: 'Status',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'Customer',
    label: 'Customer',
    options: {
      filter: true,
      sort: false,
      display: false,
    },
  },
];

export default columns;
