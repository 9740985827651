export const allServices = {
  'Tub Refinishing': false,
  'Countertop Refinishing': false,
  'Cabinet Refacing': false,
  'Wood Floor Refinishing': false,
  'Vinyl Plank Flooring Installation': false,
};

export const tubRefinishingServices = [
  '$260, Tub and Surround',
  '$520 (three walls around tub)',
  'Full Bath ‐ $650 (tub and all tile walls)',
  'Warranty Claim ‐ $0',
];

export const roomOptions = {
  'Living Room': false,
  Dining: false,
  Kitchen: false,
  Hall: false,
  'Bed 1': false,
  'Bed 2': false,
  'Bed 3': false,
  'Bath 1': false,
  'Bath 2': false,
};
