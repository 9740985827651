import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  Avatar,
} from '@material-ui/core';

import { useStyles } from '../../utils/main-styles';
import { formatDateUS } from '../../utils/helpers';

const OrderSummary = (props) => {
  const classes = useStyles();
  const { orderValues } = props;
  console.log('Order Values', orderValues);

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h6">Summary</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Property name:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.propertyName}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Property address:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.propertyAddress}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Site contact name:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.jobSiteContactName}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Site contact phone:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.jobSiteContactPhone}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Site contact email:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.jobSiteContactEmail}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Unit number:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.unitNumber}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Is property vacant?:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.isPropertyVacant}
          </Typography>
        </Box>
      </Grid>
      {orderValues.isPropertyVacant === 'no' ? (
        <>
          <Grid item xs={12} sm={4}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Alternate contact name:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.alternateContactName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={(classes.textBox, classes.textBold)}>
              <Typography display="inline" className={classes.textItem}>
                Alternate contact phone:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.alternateContactPhone}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={(classes.textBox, classes.textBold)}>
              <Typography display="inline" className={classes.textItem}>
                Alternate contact email:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.alternateContactEmail}
              </Typography>
            </Box>
          </Grid>
        </>
      ) : null}
      <Grid item xs={12} sm={6}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Date requested:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.dateRequested
              ? formatDateUS(orderValues.dateRequested)
              : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Target rental date:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.targetRentalDate
              ? formatDateUS(orderValues.targetRentalDate)
              : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Does unit have power?:{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.unitHasPower}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        {orderValues.unitHasPower === 'no' ? (
          <Box className={classes.textBox}>
            <Typography
              display="inline"
              className={(classes.textItem, classes.textBold)}
            >
              Power available date:{' '}
            </Typography>
            <Typography display="inline" className={classes.textItem}>
              {orderValues.powerAvailableDate
                ? formatDateUS(orderValues.powerAvailableDate)
                : ''}
            </Typography>
          </Box>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.textBox}>
          <Typography
            display="inline"
            className={(classes.textItem, classes.textBold)}
          >
            Does unit have water?{' '}
          </Typography>
          <Typography display="inline" className={classes.textItem}>
            {orderValues.unitHasWater}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        {orderValues.unitHasWater === 'no' ? (
          <Box className={classes.textBox}>
            <Typography
              display="inline"
              className={(classes.textItem, classes.textBold)}
            >
              Water available date:{' '}
            </Typography>
            <Typography display="inline" className={classes.textItem}>
              {orderValues.waterAvailableDate
                ? formatDateUS(orderValues.waterAvailableDate)
                : ''}
            </Typography>
          </Box>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider />
      </Grid>
      {orderValues['services']['Tub Refinishing'] ? (
        <>
          <Grid item xs={12} sm={12}>
            <Typography
              display="inline"
              className={classes.textItem}
              variant="h6"
            >
              Tub Refinishing:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Tub Refinishing Service:
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.tubRefinishingService}
              </Typography>
            </Box>
          </Grid>
          {orderValues.tubRefinishingService === 'Warranty Claim ‐ $0' ? (
            <>
              <Grid item xs={12} sm={6}>
                <Box className={classes.textBox}>
                  <Typography
                    display="inline"
                    className={(classes.textItem, classes.textBold)}
                  >
                    Warranty claim explanation:{' '}
                  </Typography>
                  <Typography display="inline" className={classes.textItem}>
                    {orderValues.tubRefinishingServiceWarrantyExplanation}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.textBox}>
                  <Typography
                    display="inline"
                    className={(classes.textItem, classes.textBold)}
                  >
                    Warranty claim pictures:{' '}
                  </Typography>
                  <div className={classes.avatarContainer}>
                    {orderValues.tubRefinishingServiceWarrantyPictures.map(
                      (secure_url, index) => (
                        <React.Fragment key={index}>
                          <Avatar
                            key={index}
                            variant="square"
                            className={classes.square}
                          >
                            <img src={secure_url} alt={secure_url} />
                          </Avatar>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </Box>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Are additional repairs needed:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.tubRefinishingAdditionalRepairNeeded}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {orderValues.tubRefinishingAdditionalRepairNeeded === 'yes' ? (
              <Box className={classes.textBox}>
                <Typography
                  display="inline"
                  className={(classes.textItem, classes.textBold)}
                >
                  Additional repair explanation:{' '}
                </Typography>
                <Typography display="inline" className={classes.textItem}>
                  {orderValues.tubRefinishingAdditionalRepairExplanation}
                </Typography>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            {orderValues.tubRefinishingAdditionalRepairNeeded === 'yes' ? (
              <Box className={classes.textBox}>
                <Typography
                  display="inline"
                  className={(classes.textItem, classes.textBold)}
                >
                  Additional repair pictures:{' '}
                </Typography>
                <div className={classes.avatarContainer}>
                  {orderValues.tubRefinishingAdditionalRepairPictures.map(
                    (secure_url, index) => (
                      <React.Fragment key={index}>
                        <Avatar
                          key={index}
                          variant="square"
                          className={classes.square}
                        >
                          <img src={secure_url} alt={secure_url} />
                        </Avatar>
                      </React.Fragment>
                    )
                  )}
                </div>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        </>
      ) : null}

      {orderValues['services']['Countertop Refinishing'] ? (
        <>
          <Grid item xs={12} sm={12}>
            <Typography
              display="inline"
              className={classes.textItem}
              variant="h6"
            >
              Countertop Refinishing:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Size:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.counterTopSize}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Additional repair needed:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.counterTopRefinishingAdditionalRepairNeeded}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {orderValues.counterTopRefinishingAdditionalRepairNeeded ===
            'yes' ? (
              <Box className={classes.textBox}>
                <Typography
                  display="inline"
                  className={(classes.textItem, classes.textBold)}
                >
                  Additional repair explanation:{' '}
                </Typography>
                <Typography display="inline" className={classes.textItem}>
                  {orderValues.counterTopAdditionalRepairExplanation}
                </Typography>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            {orderValues.counterTopRefinishingAdditionalRepairNeeded ===
            'yes' ? (
              <Box className={classes.textBox}>
                <Typography
                  display="inline"
                  className={(classes.textItem, classes.textBold)}
                >
                  Additional repair pictures:{' '}
                </Typography>
                <div className={classes.avatarContainer}>
                  {orderValues.counterTopAdditionalRepairPictures.map(
                    (secure_url, index) => (
                      <React.Fragment key={index}>
                        <Avatar
                          key={index}
                          variant="square"
                          className={classes.square}
                        >
                          <img src={secure_url} alt={secure_url} />
                        </Avatar>
                      </React.Fragment>
                    )
                  )}
                </div>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        </>
      ) : null}
      {orderValues['services']['Cabinet Refacing'] ? (
        <>
          <Grid item xs={12} sm={12}>
            <Typography
              display="inline"
              className={classes.textItem}
              variant="h6"
            >
              Cabinet Refacing:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Number of doors needed:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.cabinetRefacingNumberOfDoorsNeeded}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Number of drawer fronts:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.cabinetRefacingNumberOfDrawerFronts}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Number of drawer boxes:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.cabinetRefacingNumberOfDrawerBoxes}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Additional repair needed:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.cabinetRefacingAdditionalRepairNeeded}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {orderValues.cabinetRefacingAdditionalRepairNeeded === 'yes' ? (
              <Box className={classes.textItem}>
                <Typography
                  display="inline"
                  className={(classes.textItem, classes.textBold)}
                >
                  Additional repair explanation:{' '}
                </Typography>
                <Typography display="inline" className={classes.textItem}>
                  {orderValues.cabinetRefacingAdditionalRepairExplanation}
                </Typography>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            {orderValues.cabinetRefacingAdditionalRepairNeeded === 'yes' ? (
              <Box className={classes.textBox}>
                <Typography
                  display="inline"
                  className={(classes.textItem, classes.textBold)}
                >
                  Additional repair pictures:{' '}
                </Typography>
                <div className={classes.avatarContainer}>
                  {orderValues.cabinetRefacingAdditionalRepairPictures.map(
                    (secure_url, index) => (
                      <React.Fragment key={index}>
                        <Avatar
                          key={index}
                          variant="square"
                          className={classes.square}
                        >
                          <img src={secure_url} alt={secure_url} />
                        </Avatar>
                      </React.Fragment>
                    )
                  )}
                </div>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        </>
      ) : null}

      {orderValues['services']['Wood Floor Refinishing'] ? (
        <>
          <Grid item xs={12} sm={12}>
            <Typography
              display="inline"
              className={classes.textItem}
              variant="h6"
            >
              Wood Floor Refinishing:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Square Footage:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.woodFloorSquareFootage}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Rooms:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {Object.keys(orderValues['woodFlooringRooms']).map(
                  (room, index) => {
                    return (
                      <FormControlLabel
                        key={`vinyl-floor-room-${index}`}
                        control={
                          <Checkbox
                            color="primary"
                            checked={orderValues['woodFlooringRooms'][room]}
                            onChange={() => {}}
                            name={room}
                            disabled={true}
                          />
                        }
                        label={room}
                      />
                    );
                  }
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        </>
      ) : null}

      {orderValues['services']['Vinyl Plank Flooring Installation'] ? (
        <>
          <Grid item xs={12} sm={12}>
            <Typography
              display="inline"
              className={classes.textItem}
              variant="h6"
            >
              Vinyl Plank Flooring Installation:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Square Footage:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {orderValues.vinylPlankSquareFootage}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={classes.textBox}>
              <Typography
                display="inline"
                className={(classes.textItem, classes.textBold)}
              >
                Rooms:{' '}
              </Typography>
              <Typography display="inline" className={classes.textItem}>
                {Object.keys(orderValues['vinylPlankRooms']).map(
                  (room, index) => {
                    return (
                      <FormControlLabel
                        key={`vinyl-floor-room-${index}`}
                        control={
                          <Checkbox
                            color="primary"
                            checked={orderValues['vinylPlankRooms'][room]}
                            onChange={() => {}}
                            name={room}
                            disabled={true}
                          />
                        }
                        label={room}
                      />
                    );
                  }
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        </>
      ) : null}
    </>
  );
};
export default OrderSummary;
