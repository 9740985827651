import axios from 'axios';
const baseUrl = process.env.REACT_APP_SERVER_URL;

class AuthService {
  constructor() {
    this.url = `${baseUrl}/api/users`;
  }

  login(email, password) {
    return axios.post(`${this.url}/signin`, {
      email,
      password,
    });
  }
}

export default new AuthService();
