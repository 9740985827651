import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  AppBar,
  Toolbar,
  createStyles,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  AccountCircle,
  ShoppingCart,
  EventNote,
  Schedule,
  History,
  Person,
  ExitToApp,
} from '@material-ui/icons/';

import LocalStorageService from '../../services/localstorage.service';
import { decodeToken } from '../../utils/helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      padding: 5,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      cursor: 'pointer',
    },
    logo: {
      marginLeft: 15,
      maxWidth: 60,
    },
  })
);
const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const token = LocalStorageService.get('token');
    if (token) {
      const user = decodeToken(token);
      setUser(user);
    }
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography
            variant="h5"
            className={classes.title}
            onClick={() => {
              history.push('/');
            }}
          >
            <img src="cps-logo.png" alt="logo" className={classes.logo} />
            <img src="odin-logo.svg" alt="logo" className={classes.logo} />
          </Typography>

          {/* Menu Starts */}
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              onClick={handleOpenMenu}
              size="small"
            >
              <AccountCircle />
              {user && user.email ? (
                <>Hi, {user.username || user.email}!</>
              ) : null}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                onClick={() => {
                  history.push('/place-order');
                }}
              >
                <ShoppingCart />
                &nbsp; Place Order
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push('/estimate-request');
                }}
              >
                <EventNote />
                &nbsp; Estimate Request
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push('/scheduled-jobs');
                }}
              >
                <Schedule />
                &nbsp; Scheduled Jobs
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push('/order-history');
                }}
              >
                <History />
                &nbsp; Order History
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push('/update-password');
                }}
              >
                <Person /> &nbsp;Update Password
              </MenuItem>
              <MenuItem
                onClick={() => {
                  LocalStorageService.clear();
                  history.push('/signin');
                }}
              >
                <ExitToApp /> &nbsp; Logout
              </MenuItem>
            </Menu>
          </div>
          {/* Menu Ends */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
