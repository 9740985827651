import axios from 'axios';
import LocalStorageService from './localstorage.service';
const baseUrl = process.env.REACT_APP_SERVER_URL;

class CustomersService {
  constructor() {
    this.url = `${baseUrl}/api/customers`;
  }
  getAll() {
    const headers = {
      'Content-Type': 'application/json',
      token: LocalStorageService.get('token'),
    };
    return axios.get(`${this.url}`, { headers });
  }
}

export default new CustomersService();
