import { Switch, Route } from 'react-router-dom';

import GuardedRoute from './utils/guarded-route';

import Home from './pages/home.component';
import EstimateRequest from './pages/estimate-request.component';
import ScheduledJobs from './pages/scheduled-jobs';
import OrderHistory from './pages/order-history';

import SignIn from './pages/sign-in.component';
import UpdatePassword from './pages/update-password';
import NotFound from './pages/not-found.component';

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/update-password" component={UpdatePassword} />
        <GuardedRoute exact path="/" component={Home} />
        <GuardedRoute path="/estimate-request" component={EstimateRequest} />
        <GuardedRoute path="/scheduled-jobs" component={ScheduledJobs} />
        <GuardedRoute path="/order-history" component={OrderHistory} />

        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
};

export default App;
