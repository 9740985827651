import React, { useState } from 'react';
import {
  Container,
  Grid,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import Navbar from '../components/shared/navbar.component';
import Alert from '../components/shared/alert.component';

import LocalStorageService from '../services/localstorage.service';
import ResetPasswordService from '../services/reset-password.service';
import { decodeToken } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter: {
    textAlign: 'center',
  },
}));

const SchedulingRequestsHistory = () => {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const update = async (event) => {
    event.preventDefault();
    if (newPassword.length < 10 && confirmPassword.length < 10) {
      setErrorMessage('Password must be at least 10 characters long');
    } else if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
    } else {
      try {
        setErrorMessage('');
        setLoading(true);
        const user = decodeToken(LocalStorageService.get('token'));
        const response = await ResetPasswordService.update(
          user.email,
          newPassword
        );
        await LocalStorageService.set('token', response.data.token);
        displaySuccessMessage(5000);
        setNewPassword('');
        setConfirmPassword('');
        setLoading(false);
      } catch (e) {
        setErrorMessage('Error updating password. Please try again');
        setNewPassword('');
        setConfirmPassword('');
        setLoading(false);
      }
    }
  };

  const displaySuccessMessage = (duration) => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, duration);
  };

  return (
    <React.Fragment>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="new-password"
              label="New Password"
              type="password"
              id="new-password"
              value={newPassword}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirm-password"
              label="Confirm Password"
              type="password"
              id="confirm-password"
              value={confirmPassword}
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={update}
            >
              Update
            </Button>
            <Grid container>
              <Grid item xs>
                <Typography
                  variant="body1"
                  display="block"
                  gutterBottom
                  align="center"
                  color="error"
                >
                  {errorMessage}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
        <Grid item xs={12} sm={12} className={classes.textCenter}>
          {loading ? <CircularProgress /> : null}
        </Grid>
        <Grid item xs={12} sm={12} className={classes.textCenter}>
          {showSuccessMessage ? (
            <Alert severity="success">
              Password has been updated successfully.
            </Alert>
          ) : null}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default SchedulingRequestsHistory;
