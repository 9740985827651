import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Box,
  CssBaseline,
  AppBar,
  Tabs,
  Tab,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Typography,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import Navbar from '../components/shared/navbar.component';
import AddAnotherUnitDialog from '../components/shared/add-another-unit-dialog';
import Alert from '../components/shared/alert.component';
import SnackbarComponent from '../components/shared/snackbar.component';
import OrderSummary from '../components/shared/order-summary';

import { useStyles } from '../utils/main-styles';

import CustomersService from '../services/customer.service';
import EstimateRequestService from '../services/estimate-request.service';

import defaultValues from '../utils/default-values';
import { allServices, tubRefinishingServices } from '../utils/constants';

const { REACT_APP_CLOUD_NAME, REACT_APP_CLOUD_PRESET_NAME } = process.env;

const EstimateRequest = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);

  const [view, setView] = useState('form'); // form or summary
  const [units, setUnits] = useState([{ ...defaultValues() }]);
  const [selectedUnit, setSelectedUnit] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedUnit(newValue);
  };
  const [openAddUnitDialog, setOpenAddUnitDialog] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let response = await CustomersService.getAll();
        setCustomers(response.data.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setCustomers([]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setSelectedUnit(units.length - 1);
  }, [units.length]);

  const handleChangeView = (view) => {
    setView(view);
  };

  const validateCurrentUnitForm = () => {
    let isValid = true;
    let currentUnit = units[selectedUnit];
    let fieldsToValidate = [
      'propertyName',
      'propertyAddress',
      'unitNumber',
      'jobSiteContactName',
      'jobSiteContactEmail',
      'jobSiteContactPhone',
    ];
    for (let i = 0; i < fieldsToValidate.length; i++) {
      if (currentUnit[fieldsToValidate[i]] === '') {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const handleOpenAddAnotherUnitDialog = () => {
    if (validateCurrentUnitForm()) {
      setHasError(false);
      setOpenAddUnitDialog(true);
    } else {
      setHasError(true);
    }
  };

  const handleCloseAddAnotherUnitDialog = () => {
    setOpenAddUnitDialog(false);
  };

  const updateFieldInUnit = (e) => {
    let updatedUnits = [...units];
    updatedUnits[selectedUnit][e.target.name] = e.target.value;
    setUnits(updatedUnits);
  };

  const handleServiceChange = (event) => {
    let updatedUnits = [...units];
    updatedUnits[selectedUnit]['services'][event.target.name] =
      event.target.checked;
    setUnits(updatedUnits);
  };

  const handleWoodFloorRoomChange = (event) => {
    let updatedUnits = [...units];
    updatedUnits[selectedUnit]['woodFlooringRooms'][event.target.name] =
      event.target.checked;
    setUnits(updatedUnits);
  };

  const handleVinylRoomChange = (event) => {
    let updatedUnits = [...units];
    updatedUnits[selectedUnit]['vinylPlankRooms'][event.target.name] =
      event.target.checked;
    setUnits(updatedUnits);
  };

  const handleUploadImages = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      uploadFile(e.target.files[i], e.target.name);
    }
  };

  const uploadFile = (file, name) => {
    setOpenSnackbar(true);
    setSnackbarMessage('Uploading image...');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', REACT_APP_CLOUD_PRESET_NAME);
    const options = {
      method: 'POST',
      body: formData,
    };
    return fetch(
      `https://api.Cloudinary.com/v1_1/${REACT_APP_CLOUD_NAME}/image/upload`,
      options
    )
      .then((res) => res.json())
      .then((res) => {
        const updatedUnits = [...units];
        updatedUnits[selectedUnit][name] = [
          ...updatedUnits[selectedUnit][name],
          res.secure_url,
        ];
        setUnits(updatedUnits);
        setSnackbarMessage('Image has been uploaded successfully!');
      })
      .catch((err) => console.log(err));
  };

  const deleteImage = (name, index) => {
    const updatedUnits = [...units];
    updatedUnits[selectedUnit][name].splice(index, 1);
    setUnits(updatedUnits);
    document.getElementById(`${name}`).value = '';
  };

  const handleAddAnotherUnit = (isUnitInSameBuilding, needSameExactWork) => {
    let unitDetails = {};
    if (needSameExactWork === 'yes') {
      unitDetails = {
        ...units[selectedUnit],
        unitNumber: '',
        isPropertyVacant: 'yes',
        alternateContactName: '',
        alternateContactPhone: '',
        alternateContactEmail: '',
        dateRequested: '',
        targetRentalDate: '',
        unitHasPower: 'yes',
        powerAvailableDate: '',
        unitHasWater: 'yes',
        waterAvailableDate: '',
      };
      if (isUnitInSameBuilding === 'yes') {
        unitDetails = { ...unitDetails };
      } else {
        unitDetails = {
          ...unitDetails,
          propertyName: '',
          propertyAddress: '',
          jobSiteContactName: '',
          jobSiteContactPhone: '',
          jobSiteContactEmail: '',
        };
      }
    } else {
      unitDetails = {
        ...defaultValues(),
        services: { ...allServices },
      };
      if (isUnitInSameBuilding === 'yes') {
        unitDetails = {
          ...unitDetails,
          propertyName: units[selectedUnit]['propertyName'],
          propertyAddress: units[selectedUnit]['propertyAddress'],
          jobSiteContactName: units[selectedUnit]['jobSiteContactName'],
          jobSiteContactPhone: units[selectedUnit]['jobSiteContactPhone'],
          jobSiteContactEmail: units[selectedUnit]['jobSiteContactEmail'],
        };
      }
    }
    setUnits([...units, { ...unitDetails }]);
    setOpenAddUnitDialog(false);
  };

  // const handleSubmitForm = async () => {
  //   setLoading(true);
  //   for (let i = 0; i < units.length; i++) {
  //     await EstimateRequestService.submit(units[i]);
  //   }
  //   setLoading(false);
  //   displaySuccessMessage(5000);
  //   setView('form');
  //   setUnits([{ ...defaultValues() }]);
  //   setSelectedUnit(0);
  // };

  const handleSubmitForm = async () => {
    setLoading(true);
    for (const unit of units) {
      await EstimateRequestService.submit(unit);
    }
    setLoading(false);
    displaySuccessMessage(5000);
    setView('form');
    setUnits([{ ...defaultValues() }]);
  };

  const displaySuccessMessage = (duration) => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, duration || 0);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid item xs={12} sm={12} className={classes.textCenter}>
            <Box mb={2}>
              <Typography variant="h5">Estimate Request Form</Typography>
            </Box>
          </Grid>
          <React.Fragment>
            <Grid container spacing={3}>
              <AppBar position="static" className={classes.appBar}>
                <Tabs value={selectedUnit} onChange={handleTabChange}>
                  {units.map((unit, index) => {
                    return (
                      <Tab
                        label={`Unit - ${unit.unitNumber}`}
                        key={`tab-${index}`}
                        id={`tab-id-${index}`}
                        value={index}
                      />
                    );
                  })}
                </Tabs>
              </AppBar>
            </Grid>
            <Grid container spacing={3}>
              {view === 'form' && units[selectedUnit] ? (
                <>
                  <Grid item sm={12} xs={12}>
                    <Box className={classes.sectionHeader}>
                      <Typography variant="h6">Job Information</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink id="propertyName-label">
                      Property Name
                    </InputLabel>
                    <Select
                      id="propertyName"
                      name="propertyName"
                      label="Property Name"
                      placeholder="Property Name"
                      fullWidth
                      variant="outlined"
                      required
                      error={units[selectedUnit].propertyName === ''}
                      value={units[selectedUnit].propertyName}
                      onChange={updateFieldInUnit}
                    >
                      {customers.map((customer, id) => (
                        <MenuItem
                          key={`property-${id}`}
                          value={customer.DisplayName}
                        >
                          {customer.DisplayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="propertyAddress"
                      name="propertyAddress"
                      label="Property Address"
                      placeholder="Property Address"
                      fullWidth
                      multiline
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      aria-required
                      error={units[selectedUnit].propertyAddress === ''}
                      helperText="*Required"
                      value={units[selectedUnit].propertyAddress}
                      onChange={updateFieldInUnit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="unitNumber"
                        name="unitNumber"
                        label="Unit Number"
                        placeholder="Unit Number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        required
                        error={units[selectedUnit].unitNumber === ''}
                        helperText="*Required"
                        value={units[selectedUnit].unitNumber}
                        onChange={updateFieldInUnit}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box border={1} p={3}>
                      <Typography color="textSecondary">
                        Jobsite contact:
                      </Typography>
                      <TextField
                        id="jobSiteContactName"
                        name="jobSiteContactName"
                        label="Name"
                        fullWidth
                        error={units[selectedUnit].jobSiteContactName === ''}
                        helperText="*Required"
                        value={units[selectedUnit].jobSiteContactName}
                        onChange={updateFieldInUnit}
                      />
                      <TextField
                        id="jobSiteContactPhone"
                        name="jobSiteContactPhone"
                        label="Phone"
                        fullWidth
                        error={units[selectedUnit].jobSiteContactPhone === ''}
                        helperText="*Required"
                        value={units[selectedUnit].jobSiteContactPhone}
                        onChange={updateFieldInUnit}
                      />
                      <TextField
                        id="jobSiteContactEmail"
                        name="jobSiteContactEmail"
                        label="Email"
                        fullWidth
                        error={units[selectedUnit].jobSiteContactEmail === ''}
                        helperText="*Required"
                        value={units[selectedUnit].jobSiteContactEmail}
                        onChange={updateFieldInUnit}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Box className={classes.sectionHeader}></Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={6}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Is property vacant?
                        </FormLabel>
                        <RadioGroup
                          aria-label="isPropertyVacant"
                          name="isPropertyVacant"
                          row
                          value={units[selectedUnit].isPropertyVacant}
                          onChange={updateFieldInUnit}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {units[selectedUnit].isPropertyVacant === 'no' ? (
                    <Grid item xs={12} sm={12}>
                      <Grid item xs={12} sm={6}>
                        <Box border={1} p={3}>
                          <Typography>
                            Alternate contact: (If occupied, provide resident
                            contact)
                          </Typography>
                          <TextField
                            id="alternateContactName"
                            name="alternateContactName"
                            label="Name"
                            fullWidth
                            value={units[selectedUnit].alternateContactName}
                            onChange={updateFieldInUnit}
                          />
                          <TextField
                            id="alternateContactPhone"
                            name="alternateContactPhone"
                            label="Phone"
                            fullWidth
                            value={units[selectedUnit].alternateContactPhone}
                            onChange={updateFieldInUnit}
                          />
                          <TextField
                            id="alternateContactEmail"
                            name="alternateContactEmail"
                            label="Email"
                            fullWidth
                            value={units[selectedUnit].alternateContactEmail}
                            onChange={updateFieldInUnit}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="dateRequested"
                      name="dateRequested"
                      label="Date requested"
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={units[selectedUnit].dateRequested}
                      onChange={updateFieldInUnit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="targetRentalDate"
                      name="targetRentalDate"
                      label="Target rental date"
                      placeholder="Target rental date"
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={units[selectedUnit].targetRentalDate}
                      onChange={updateFieldInUnit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Does unit have power?
                      </FormLabel>
                      <RadioGroup
                        aria-label="unitHasPower"
                        name="unitHasPower"
                        row
                        value={units[selectedUnit].unitHasPower}
                        onChange={updateFieldInUnit}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {units[selectedUnit].unitHasPower === 'no' ? (
                      <TextField
                        id="powerAvailableDate"
                        name="powerAvailableDate"
                        label="If not, date power will be turned on:"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        value={units[selectedUnit].powerAvailableDate}
                        onChange={updateFieldInUnit}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Does unit have running water?
                      </FormLabel>
                      <RadioGroup
                        aria-label="unitHasWater"
                        name="unitHasWater"
                        row
                        value={units[selectedUnit].unitHasWater}
                        onChange={updateFieldInUnit}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {units[selectedUnit].unitHasWater === 'no' ? (
                      <TextField
                        id="waterAvailableDate"
                        name="waterAvailableDate"
                        label="If not, date water will be turned on:"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        variant="outlined"
                        value={units[selectedUnit].waterAvailableDate}
                        onChange={updateFieldInUnit}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography variant="body1" color="textSecondary">
                        Services:
                      </Typography>
                    </Box>
                    <FormGroup row>
                      {Object.keys(units[selectedUnit].services).map(
                        (key, index) => (
                          <FormControlLabel
                            key={`service-${index}`}
                            control={
                              <Checkbox
                                color="primary"
                                checked={units[selectedUnit].services[key]}
                                onChange={handleServiceChange}
                                name={key}
                              />
                            }
                            label={key}
                          />
                        )
                      )}
                    </FormGroup>
                  </Grid>
                  {units[selectedUnit]['services']['Tub Refinishing'] ? (
                    <>
                      <Grid item sm={12} xs={12}>
                        <Box className={classes.sectionHeader}>
                          <Typography variant="h6">Tub Refinishing</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={6}>
                          <InputLabel shrink id="tubRefinishingService-label">
                            Select service needed:
                          </InputLabel>
                          <Select
                            id="tubRefinishingService"
                            name="tubRefinishingService"
                            label="Tub Refinishing Service"
                            placeholder="Tub Refinishing Service"
                            fullWidth
                            variant="outlined"
                            value={units[selectedUnit].tubRefinishingService}
                            onChange={updateFieldInUnit}
                          >
                            {tubRefinishingServices.map((service, id) => (
                              <MenuItem
                                key={`tub-refinishing-service-${id}`}
                                value={service}
                              >
                                {service}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                      {units[selectedUnit].tubRefinishingService ===
                      'Warranty Claim ‐ $0' ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="tubRefinishingServiceWarrantyExplanation"
                              name="tubRefinishingServiceWarrantyExplanation"
                              label="Please share details:"
                              placeholder="Please share details:"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={
                                units[selectedUnit]
                                  .tubRefinishingServiceWarrantyExplanation
                              }
                              onChange={updateFieldInUnit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormLabel component="legend">
                              Include pictures with the form:
                            </FormLabel>
                            <br />
                            <input
                              id="tubRefinishingServiceWarrantyPictures"
                              name="tubRefinishingServiceWarrantyPictures"
                              type="file"
                              accept="image/*"
                              onChange={handleUploadImages}
                            />
                            <div className={classes.avatarContainer}>
                              {units[selectedUnit][
                                'tubRefinishingServiceWarrantyPictures'
                              ].map((secure_url, index) => (
                                <React.Fragment key={index}>
                                  <Delete
                                    onClick={() => {
                                      deleteImage(
                                        'tubRefinishingServiceWarrantyPictures',
                                        index
                                      );
                                    }}
                                  />
                                  <Avatar
                                    key={index}
                                    variant="square"
                                    className={classes.square}
                                  >
                                    <img src={secure_url} alt={secure_url} />
                                  </Avatar>
                                </React.Fragment>
                              ))}
                            </div>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={6}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Are additional repairs needed:
                            </FormLabel>
                            <RadioGroup
                              aria-label="tubRefinishingAdditionalRepairNeeded"
                              id="tubRefinishingAdditionalRepairNeeded"
                              name="tubRefinishingAdditionalRepairNeeded"
                              row
                              value={
                                units[selectedUnit]
                                  .tubRefinishingAdditionalRepairNeeded
                              }
                              onChange={updateFieldInUnit}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {units[selectedUnit]
                        .tubRefinishingAdditionalRepairNeeded === 'yes' ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="tubRefinishingAdditionalRepairExplanation"
                              name="tubRefinishingAdditionalRepairExplanation"
                              label="Please share details:"
                              placeholder="Please share details:"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={
                                units[selectedUnit]
                                  .tubRefinishingAdditionalRepairExplanation
                              }
                              onChange={updateFieldInUnit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormLabel component="legend">
                              Include pictures with the form:
                            </FormLabel>
                            <br />
                            <input
                              id="tubRefinishingAdditionalRepairPictures"
                              name="tubRefinishingAdditionalRepairPictures"
                              type="file"
                              accept="image/*"
                              onChange={handleUploadImages}
                            />
                            <div className={classes.avatarContainer}>
                              {units[selectedUnit][
                                'tubRefinishingAdditionalRepairPictures'
                              ].map((secure_url, index) => (
                                <React.Fragment key={index}>
                                  <Delete
                                    onClick={() => {
                                      deleteImage(
                                        'tubRefinishingAdditionalRepairPictures',
                                        index
                                      );
                                    }}
                                  />
                                  <Avatar
                                    key={index}
                                    variant="square"
                                    className={classes.square}
                                  >
                                    <img src={secure_url} alt={secure_url} />
                                  </Avatar>
                                </React.Fragment>
                              ))}
                            </div>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {units[selectedUnit]['services']['Countertop Refinishing'] ? (
                    <>
                      <Grid item sm={12} xs={12}>
                        <Box className={classes.sectionHeader}>
                          <Typography variant="h6">
                            Countertop Refinishing
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="counterTopSize"
                            name="counterTopSize"
                            label="Enter approximate size of countertop (in linear feet)"
                            placeholder="Enter approximate size of countertop (in linear feet)"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            required
                            value={units[selectedUnit].counterTopSize}
                            onChange={updateFieldInUnit}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={6}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Are additional repairs needed:
                            </FormLabel>
                            <RadioGroup
                              aria-label="counterTopRefinishingAdditionalRepairNeeded"
                              id="counterTopRefinishingAdditionalRepairNeeded"
                              name="counterTopRefinishingAdditionalRepairNeeded"
                              row
                              value={
                                units[selectedUnit]
                                  .counterTopRefinishingAdditionalRepairNeeded
                              }
                              onChange={updateFieldInUnit}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {units[selectedUnit]
                        .counterTopRefinishingAdditionalRepairNeeded ===
                      'yes' ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="counterTopAdditionalRepairExplanation"
                              name="counterTopAdditionalRepairExplanation"
                              label="Please share details:"
                              placeholder="Please share details:"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={
                                units[selectedUnit]
                                  .counterTopAdditionalRepairExplanation
                              }
                              onChange={updateFieldInUnit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormLabel component="legend">
                              Include pictures with the form:
                            </FormLabel>
                            <br />
                            <input
                              id="counterTopAdditionalRepairPictures"
                              name="counterTopAdditionalRepairPictures"
                              type="file"
                              accept="image/*"
                              onChange={handleUploadImages}
                            />
                            <div className={classes.avatarContainer}>
                              {units[selectedUnit][
                                'counterTopAdditionalRepairPictures'
                              ].map((secure_url, index) => (
                                <React.Fragment key={index}>
                                  <Delete
                                    onClick={() => {
                                      deleteImage(
                                        'counterTopAdditionalRepairPictures',
                                        index
                                      );
                                    }}
                                  />
                                  <Avatar
                                    key={index}
                                    variant="square"
                                    className={classes.square}
                                  >
                                    <img src={secure_url} alt={secure_url} />
                                  </Avatar>
                                </React.Fragment>
                              ))}
                            </div>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {units[selectedUnit]['services']['Cabinet Refacing'] ? (
                    <>
                      <Grid item sm={12} xs={12}>
                        <Box className={classes.sectionHeader}>
                          <Typography variant="h6">Cabinet Refacing</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="cabinetRefacingNumberOfDoorsNeeded"
                            name="cabinetRefacingNumberOfDoorsNeeded"
                            label="Number of doors needed"
                            placeholder="Number of doors needed"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            required
                            value={
                              units[selectedUnit]
                                .cabinetRefacingNumberOfDoorsNeeded
                            }
                            onChange={updateFieldInUnit}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="cabinetRefacingNumberOfDrawerFronts"
                            name="cabinetRefacingNumberOfDrawerFronts"
                            label="Number of drawer fronts needed"
                            placeholder="Number of drawer fronts needed"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            required
                            value={
                              units[selectedUnit]
                                .cabinetRefacingNumberOfDrawerFronts
                            }
                            onChange={updateFieldInUnit}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="cabinetRefacingNumberOfDrawerBoxes"
                            name="cabinetRefacingNumberOfDrawerBoxes"
                            label="Number of drawer boxes needed"
                            placeholder="Number of drawer boxes needed"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            required
                            value={
                              units[selectedUnit]
                                .cabinetRefacingNumberOfDrawerBoxes
                            }
                            onChange={updateFieldInUnit}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={6}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Are additional repairs needed:
                            </FormLabel>
                            <RadioGroup
                              aria-label="cabinetRefacingAdditionalRepairNeeded"
                              id="cabinetRefacingAdditionalRepairNeeded"
                              name="cabinetRefacingAdditionalRepairNeeded"
                              row
                              value={
                                units[selectedUnit]
                                  .cabinetRefacingAdditionalRepairNeeded
                              }
                              onChange={updateFieldInUnit}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {units[selectedUnit]
                        .cabinetRefacingAdditionalRepairNeeded === 'yes' ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="cabinetRefacingAdditionalRepairExplanation"
                              name="cabinetRefacingAdditionalRepairExplanation"
                              label="Please share details:"
                              placeholder="Please share details:"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              value={
                                units[selectedUnit]
                                  .cabinetRefacingAdditionalRepairExplanation
                              }
                              onChange={updateFieldInUnit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormLabel component="legend">
                              Include pictures with the form:
                            </FormLabel>
                            <br />
                            <input
                              id="cabinetRefacingAdditionalRepairPictures"
                              name="cabinetRefacingAdditionalRepairPictures"
                              type="file"
                              accept="image/*"
                              onChange={handleUploadImages}
                            />
                            <div className={classes.avatarContainer}>
                              {units[selectedUnit][
                                'cabinetRefacingAdditionalRepairPictures'
                              ].map((secure_url, index) => (
                                <React.Fragment key={index}>
                                  <Delete
                                    onClick={() => {
                                      deleteImage(
                                        'cabinetRefacingAdditionalRepairPictures',
                                        index
                                      );
                                    }}
                                  />
                                  <Avatar
                                    key={index}
                                    variant="square"
                                    className={classes.square}
                                  >
                                    <img src={secure_url} alt={secure_url} />
                                  </Avatar>
                                </React.Fragment>
                              ))}
                            </div>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {units[selectedUnit]['services']['Wood Floor Refinishing'] ? (
                    <>
                      <Grid item sm={12} xs={12}>
                        <Box className={classes.sectionHeader}>
                          <Typography variant="h6">
                            Wood Floor Refinishing
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="woodFloorSquareFootage"
                          name="woodFloorSquareFootage"
                          label="Approximate square footage"
                          placeholder="Approximate square footage"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          required
                          value={units[selectedUnit].woodFloorSquareFootage}
                          onChange={updateFieldInUnit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box>
                          <Typography variant="body1" color="textSecondary">
                            Rooms:
                          </Typography>
                        </Box>
                        <FormGroup row>
                          {Object.keys(
                            units[selectedUnit]['woodFlooringRooms']
                          ).map((room, index) => (
                            <FormControlLabel
                              key={`wood-floor-room-${index}`}
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    units[selectedUnit]['woodFlooringRooms'][
                                      room
                                    ]
                                  }
                                  onChange={handleWoodFloorRoomChange}
                                  name={room}
                                />
                              }
                              label={room}
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                    </>
                  ) : null}
                  {units[selectedUnit]['services'][
                    'Vinyl Plank Flooring Installation'
                  ] ? (
                    <>
                      <Grid item sm={12} xs={12}>
                        <Box className={classes.sectionHeader}>
                          <Typography variant="h6">
                            Vinyl Plank Flooring Installation
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="vinylPlankSquareFootage"
                          name="vinylPlankSquareFootage"
                          label="Approximate square footage"
                          placeholder="Approximate square footage"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          required
                          value={units[selectedUnit].vinylPlankSquareFootage}
                          onChange={updateFieldInUnit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box>
                          <Typography variant="body1" color="textSecondary">
                            Rooms:
                          </Typography>
                        </Box>
                        <FormGroup row>
                          {Object.keys(
                            units[selectedUnit]['vinylPlankRooms']
                          ).map((room, index) => (
                            <FormControlLabel
                              key={`vinyl-plank-room-${index}`}
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    units[selectedUnit]['vinylPlankRooms'][room]
                                  }
                                  onChange={handleVinylRoomChange}
                                  name={room}
                                />
                              }
                              label={room}
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item sm={12} xs={12}>
                    <Box className={classes.sectionHeader}></Box>
                  </Grid>
                  <Grid item xs={12} sm={12} className={classes.textCenter}>
                    {/* Error message */}
                    {hasError ? (
                      <Typography variant="body2" color="error" gutterBottom>
                        Please fill all the required fields..
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="inline" mr={2}>
                      <Button
                        size="large"
                        variant="contained"
                        type="button"
                        onClick={handleOpenAddAnotherUnitDialog}
                      >
                        Add Another Unit
                      </Button>
                    </Box>
                    <Box display="inline" mr={2}>
                      {selectedUnit > 0 ? (
                        <Button
                          size="large"
                          variant="contained"
                          type="button"
                          color="secondary"
                          disabled
                          onClick={() => {
                            console.log('Remove Unit here');
                          }}
                        >
                          Remove Unit
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.textRight}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => {
                        handleChangeView('summary');
                      }}
                    >
                      Done
                    </Button>
                  </Grid>
                </>
              ) : null}
              {view === 'summary' ? (
                <>
                  <OrderSummary orderValues={units[selectedUnit]} />
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="large"
                      variant="contained"
                      type="button"
                      onClick={() => {
                        handleChangeView('form');
                      }}
                    >
                      Make Changes
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.textRight}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSubmitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} className={classes.textCenter}>
                {loading ? <CircularProgress /> : null}
              </Grid>
              <Grid item xs={12} sm={12} className={classes.textCenter}>
                {showSuccessMessage ? (
                  <Alert severity="success">
                    Inspection form has been submitted and email has been sent
                    successfully!
                  </Alert>
                ) : null}
              </Grid>
            </Grid>
          </React.Fragment>
        </Paper>
        <AddAnotherUnitDialog
          openAddUnitDialog={openAddUnitDialog}
          handleCloseAddAnotherUnitDialog={handleCloseAddAnotherUnitDialog}
          handleAddAnotherUnit={handleAddAnotherUnit}
        />
        <SnackbarComponent
          openSnackbar={openSnackbar}
          snackbarMessage={snackbarMessage}
          handleCloseSnackbar={() => {
            setOpenSnackbar(false);
            setSnackbarMessage('');
          }}
        />
      </main>
    </React.Fragment>
  );
};

export default EstimateRequest;
