import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import { validateEmail } from '../utils/helpers';

import LocalStorageService from '../services/localstorage.service';
import AuthService from '../services/auth.service';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter: {
    textAlign: 'center',
  },
}));

const SignIn = () => {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const login = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      setErrorMessage('Please enter email and password');
    } else {
      const isEmailValid = validateEmail(email);
      if (!isEmailValid) {
        setErrorMessage('Please enter valid email address');
      }
      if (isEmailValid && password) {
        setErrorMessage('');
        try {
          setLoading(true);
          const response = await AuthService.login(email, password);
          await LocalStorageService.set('token', response.data.token);
          setLoading(false);
          history.push('/');
        } catch (e) {
          setLoading(false);
          setErrorMessage(
            'Error signing in. Please try again with valid email and password'
          );
        }
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={login}
          >
            Sign In
          </Button>
          <Grid item xs={12} sm={12} className={classes.textCenter}>
            {loading ? <CircularProgress /> : null}
          </Grid>
          <Grid container>
            <Grid item xs>
              <Typography
                variant="body1"
                display="block"
                gutterBottom
                align="center"
                color="error"
              >
                {errorMessage}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
    </Container>
  );
};

export default SignIn;
