import { allServices, roomOptions } from './constants';

const defaultValues = () => {
  return {
    propertyName: '',
    propertyAddress: '',
    unitNumber: '',
    jobSiteContactName: '',
    jobSiteContactPhone: '',
    jobSiteContactEmail: '',
    isPropertyVacant: 'yes',
    alternateContactName: '',
    alternateContactPhone: '',
    alternateContactEmail: '',
    dateRequested: '',
    targetRentalDate: '',
    unitHasPower: 'yes',
    powerAvailableDate: '',
    unitHasWater: 'yes',
    waterAvailableDate: '',
    services: { ...allServices },
    tubRefinishingService: '',
    tubRefinishingServiceWarrantyExplanation: '',
    tubRefinishingServiceWarrantyPictures: [],
    tubRefinishingAdditionalRepairNeeded: 'no',
    tubRefinishingAdditionalRepairExplanation: '',
    tubRefinishingAdditionalRepairPictures: [],
    counterTopSize: '',
    counterTopRefinishingAdditionalRepairNeeded: 'no',
    counterTopAdditionalRepairExplanation: '',
    counterTopAdditionalRepairPictures: [],
    cabinetRefacingNumberOfDoorsNeeded: '',
    cabinetRefacingNumberOfDrawerFronts: '',
    cabinetRefacingNumberOfDrawerBoxes: '',
    cabinetRefacingAdditionalRepairNeeded: 'no',
    cabinetRefacingAdditionalRepairExplanation: '',
    cabinetRefacingAdditionalRepairPictures: [],
    woodFloorSquareFootage: '',
    woodFlooringRooms: {
      ...roomOptions,
    },
    vinylPlankSquareFootage: '',
    vinylPlankRooms: {
      ...roomOptions,
    },
  };
};

export default defaultValues;
