import jwt_decode from 'jwt-decode';

export const verifyToken = (token) => {
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    return false;
  }
  return true;
};

export const decodeToken = (token) => {
  const decoded = jwt_decode(token);
  return decoded;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
};

export const formatDateUS = (date) => {
  var newDate = new Date(date);
  return (
    (newDate.getMonth() > 8
      ? newDate.getMonth() + 1
      : '0' + (newDate.getMonth() + 1)) +
    '/' +
    (newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate()) +
    '/' +
    newDate.getFullYear()
  );
};
