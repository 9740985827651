import React from 'react';
import { useHistory } from 'react-router-dom';

import { CssBaseline, Link, Grid, Box } from '@material-ui/core';

import Navbar from '../components/shared/navbar.component';

const NotFound = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar />
      <Grid container>
        <Grid item>
          <Box p={3}>
            Page not found.{'  '}
            <Link
              onClick={() => {
                history.push('/');
              }}
              style={{ cursor: 'pointer' }}
            >
              Go to home.
            </Link>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default NotFound;
